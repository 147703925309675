<template>
    <div class="home">
        <MyAccount />
    </div>
</template>

<script>

import MyAccount from '@/components/account/MyAccount'
export default {
    name: 'MyAccountView',
    components:{
        MyAccount
    }
}
</script>