<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-4">
        <div class="card section">
          <ValidationObserver v-slot="{ invalid }">
            <form>
              <b-field>
                <ValidationProvider
                  name="oldPassword"
                  rules="required|userpass"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :label="t('auth.currentPassword')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      type="password"
                      v-model="changedPassword.password"
                      :has-counter="false"
                      minlength="6"
                      password-reveal
                      required
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field>
                <ValidationProvider
                  name="password"
                  rules="required|userpass"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :label="t('auth.newPassword')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      type="password"
                      ref="password"
                      v-model="changedPassword.newPassword"
                      :has-counter="false"
                      minlength="6"
                      password-reveal
                      required
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field>
                <ValidationProvider
                  name="confirm"
                  rules="required|confirmed:password"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :label="t('auth.repeatPassword')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      type="password"
                      v-model="changedPassword.repeatPassword"
                      password-reveal
                      requried
                    ></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <p class="has-text-centered">
                <b-button :disabled="invalid" @click="update">{{t('auth.changePassword')}}</b-button>
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Action } from "../../store/config_actions";

export default {
  name: "MyAccountEdit",
  mixins: [CommonHelpers],
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {},

  data: function () {
    return {
      isLoading: false,
      isOpen: false,
      passwordIsOpen: false,
      accountData: {},
      changedPassword: {
        password: "",
        newPassword: "",
        repeatPassword: "",
      },
      // accountPassword: "",
      // newPassword: "",
      // confirmation: "",
    };
  },

  mounted() {
    this.accountData = {
      firstName: this.session.firstName,
      lastName: this.session.lastName,
      email: this.session.email,
    };
  },

  watch: {},

  computed: {
    session() {
      return this.$store.state.auth.session;
    },
  },

  methods: {
    update() {
      this.$store
        .dispatch(Action.AUTH_CHANGE_PASSWORD, this.changedPassword)
        .then(() => {
          this.mSuccessSnack(this.t('auth.passwordChanged'));
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>