<template>
  <div class="m-4">
    <MyAccountEdit></MyAccountEdit>
  </div>
</template>

<script>
import MyAccountEdit from './MyAccountEdit'

export default {
  name: 'MyAccount',
  components: {
    MyAccountEdit
  },

  props: {},

  data: function () {
    return {
    }
  },

  mounted() {
  },

  watch: {
  },

  computed: {
  },

  methods: {
  }
}
</script>

<style scoped>
</style>