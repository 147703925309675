var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"card section"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('b-field',[_c('ValidationProvider',{attrs:{"name":"oldPassword","rules":"required|userpass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('auth.currentPassword'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0])}},[_c('b-input',{attrs:{"type":"password","has-counter":false,"minlength":"6","password-reveal":"","required":""},model:{value:(_vm.changedPassword.password),callback:function ($$v) {_vm.$set(_vm.changedPassword, "password", $$v)},expression:"changedPassword.password"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|userpass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('auth.newPassword'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0])}},[_c('b-input',{ref:"password",attrs:{"type":"password","has-counter":false,"minlength":"6","password-reveal":"","required":""},model:{value:(_vm.changedPassword.newPassword),callback:function ($$v) {_vm.$set(_vm.changedPassword, "newPassword", $$v)},expression:"changedPassword.newPassword"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('auth.repeatPassword'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0])}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","requried":""},model:{value:(_vm.changedPassword.repeatPassword),callback:function ($$v) {_vm.$set(_vm.changedPassword, "repeatPassword", $$v)},expression:"changedPassword.repeatPassword"}})],1)]}}],null,true)})],1),_c('p',{staticClass:"has-text-centered"},[_c('b-button',{attrs:{"disabled":invalid},on:{"click":_vm.update}},[_vm._v(_vm._s(_vm.t('auth.changePassword')))])],1)],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }